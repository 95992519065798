import React from "react";
import { SocialProps } from "../OtherAssets/Social/Social";
import Footer from "./Footer";
import Navbar from "./Navbar";

type LayoutProps = {
  children: React.ReactNode;
  social?: SocialProps | null;
  logo?: LogoProps | null;
};
export type LogoProps = {
  image?: string | null;
  name?: string | null;
};

const Layout = ({ children, social, logo }: LayoutProps) => {
  return (
    <>
      <Navbar image={logo?.image} />
      {children}
      <Footer social={social} image={logo?.image} />
    </>
  );
};

export default Layout;
