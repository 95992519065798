import { Select } from "@mantine/core";
import { useRouter } from "next/router";
import React from "react";

type Props = {};

function LangButton({}: Props) {
  const router = useRouter();
  const { locale } = router;
  const handleLocaleChange = (value: string) => {
    console.log(router);
    if (router.pathname === "/blogs/[id]") {
      router.push(router.route, `/blogs/404`, {
        locale: value,
      });
    } else {
      router.push(router.route, router.asPath, {
        locale: value,
      });
    }
  };
  return (
    <>
      <Select
        defaultValue={locale}
        data={[
          { value: "en", label: "🇺🇸 English" },
          { value: "th", label: "🇹🇭 ภาษาไทย" },
          { value: "zh-CN", label: "🇨🇳 中文" },
          { value: "ko-KR", label: "🇰🇷 Korea" },
        ]}
        onChange={handleLocaleChange}
        style={{ width: "120px" }}
      />
    </>
  );
}

export default LangButton;
