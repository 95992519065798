import React from "react";

type NavClosingProps = {
  onClick?: () => void;
};

const NavClosing = ({onClick}: NavClosingProps) => {
  return (
    <>
      <button className="navbar-close" onClick={onClick}>
        <svg
          className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </>
  );
};

export default NavClosing;
