import { useRouter } from "next/router";
import React from "react";

type HeaderProps = {
  backgroundUrl: string;
  children: React.ReactNode;
};

const Header = ({ backgroundUrl, children }: HeaderProps) => {
  const router = useRouter();
  const path = router.asPath;
  return (
    <>
      <section
        id="header-section"
        className={`bg-cover bg-no-repeat py-24 ${
          path === "/" ? "md:py-30" : "md:py-40"
        } mb-16 lg:mb-10`}
        style={{
          backgroundImage: `url(${backgroundUrl})`,
        }}
      >
        {children}
      </section>
    </>
  );
};

export default Header;
