import Image from "next/image";
import Link from "next/link";
import React from "react";
import ScrollIntoView from "react-scroll-into-view";
import Social, { SocialProps } from "../OtherAssets/Social/Social";
import { LogoProps } from "./Layout";

type FooterProps = {
  social?: SocialProps | null;
  image?: string | null;
};

const Footer = ({ social, image }: FooterProps) => {
  return (
    <>
      <section>
        <footer className="py-20">
          <div className="container px-4 mx-auto max-w-6xl">
            <div className="flex flex-wrap items-center lg:justify-between justify-center">
              <div className="w-auto mb-6 lg:mb-0">
                <Link className="text-gray-600 text-2xl leading-none" href="/">
                  {image && (
                    <Image
                      className=""
                      src={`${process.env.NEXT_PUBLIC_API_ENDPOINT}${image}`}
                      alt="rayongboard-logo-footer"
                      width={160}
                      height={32}
                    />
                  )}
                </Link>
              </div>
              <div className="w-full lg:w-auto">
                <ul className="flex flex-wrap space-x-8 items-center justify-center">
                  <li className="mb-2 md:mb-0">
                    <Link
                      className="text-sm text-gray-900 hover:text-gray-700"
                      href="/"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="mb-2 md:mb-0">
                    <Link
                      className="text-sm text-gray-900 hover:text-gray-700"
                      href="/blogs"
                    >
                      <p>Blog</p>
                    </Link>
                  </li>
                  <li className="mb-2 md:mb-0">
                    <Link
                      className="text-sm text-gray-900 hover:text-gray-700"
                      href="/#faq-section"
                    >
                      <p>FAQ</p>
                    </Link>
                  </li>
                  <li className="mb-2 md:mb-0">
                    <Link
                      className="text-sm text-gray-900 hover:text-gray-700"
                      href="/about"
                    >
                      About
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-16 mb-8 border-b border-gray-50 " />
          <div className="container px-4 mx-auto max-w-6xl">
            <div className="flex flex-wrap lg:justify-between justify-center items-center">
              <p className="w-full lg:w-auto mt-4 lg:mt-0 order-last lg:order-first text-sm text-gray-400 flex justify-center">
                All rights reserved © Rayong Board 2020
              </p>
              <div className="order-first lg:order-last flex">
                <Social
                  facebook={social?.facebook}
                  instagram={social?.instagram}
                  twitter={social?.twitter}
                  line={social?.line}
                />
              </div>
            </div>
          </div>
        </footer>
      </section>
    </>
  );
};

export default Footer;
