import React from "react";

type NavBurgerProps = {
  onClick?: () => void;
};

const NavBurger = ({ onClick }: NavBurgerProps) => {
  return (
    <>
      <button
        className="block navbar-burger text-gray-50 hover:text-gray-200 focus:outline-none opacity-100"
        onClick={onClick}
      >
        <svg
          className="h-4 w-4"
          fill="currentColor "
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Mobile menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </button>
    </>
  );
};

export default NavBurger;
