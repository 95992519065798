import React from "react";
import Link from "next/link";
import Image from "next/image";

export type SocialProps = {
  facebook?: string | null;
  instagram?: string | null;
  line?: string | null;
  twitter?: string | null;
};

const Social = ({ facebook, instagram, line, twitter }: SocialProps) => {
  return (
    <>
      <div className="flex px-0">
        {/* facebook */}
        {facebook && (
          <Link className="" href={facebook} target="_blank">
            <Image
              src="/socials/facebook.svg"
              alt="facebook"
              width={24}
              height={24}
            />
          </Link>
        )}

        {/* instagram */}
        {instagram && (
          <Link className="ml-4" href={instagram} target="_blank">
            <Image
              src="/socials/instagram.svg"
              alt="instagram"
              width={24}
              height={24}
            />
          </Link>
        )}

        {/* twittwer */}
        {twitter && (
          <Link className="ml-4" href={twitter} target="_blank">
            <Image
              src="/socials/twitter.svg"
              alt="twitter"
              width={26}
              height={26}
            />
          </Link>
        )}

        {/* line */}
        {line && (
          <Link className="ml-4" href={line} target="_blank">
            <Image src="/socials/line.svg" alt="line" width={30} height={30} />
          </Link>
        )}
      </div>
    </>
  );
};

export default Social;
