import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { useScrollYPosition } from "react-use-scroll-position";
import LangButton from "../Buttons/LangButton/LangButton";
import NavBurger from "../Buttons/NavBurger/NavBurger";
import NavClosing from "../Buttons/NavClosing/NavClosing";
import { LogoProps } from "./Layout";

type NavbarProps = {
  image?: string | null;
};

type MenuType = {
  en: { home: string; blog: string; faq: string; about: string };
  th: { home: string; blog: string; faq: string; about: string };
  "zh-CN": { home: string; blog: string; faq: string; about: string };
  "ko-KR": { home: string; blog: string; faq: string; about: string };
};
const navbarMenu: MenuType = {
  en: { home: "Home", blog: "Blog", faq: "FAQ", about: "About" },
  th: {
    home: "หน้าหลัก",
    blog: "ข่าวสาร",
    faq: "คำถามที่พบบ่อย",
    about: "เกี่ยวกับ",
  },
  "zh-CN": { home: "家", blog: "博客", faq: "常問問題", about: "關於" },
  "ko-KR": {
    home: "집",
    blog: "블로그",
    faq: "자주하는 질문",
    about: "에 대한",
  },
};

const Navbar = ({ image }: NavbarProps) => {
  const scrollY = useScrollYPosition();
  const [isOpenNav, setIsOpenNav] = useState(false);
  const { locale } = useRouter();
  const localeList = ["en", "th", "zh-CN", "ko-KR"];
  const lang = locale as "en" | "th" | "zh-CN" | "ko-KR";
  return (
    <>
      <section>
        <div
          className={`${
            scrollY >= 100
              ? `bg-primary-600 backdrop-opacity-100 backdrop-blur-sm z-10 `
              : ""
          } w-full px-6 mx-auto fixed`}
        >
          <nav className=" relative flex justify-between items-center py-5 backdrop-blur-sm backdrop-opacity-10 bg-opacity-10">
            <Link className="text-gray-600 text-2xl leading-none" href="/">
              {image && (
                <Image
                  className="rayongboard-logo"
                  src={`${process.env.NEXT_PUBLIC_API_ENDPOINT}${image}`}
                  alt="LOGO"
                  width={256}
                  height={48}
                />
              )}
            </Link>
            <div className="lg:hidden flex justify-center ">
              <NavBurger
                onClick={() => {
                  setIsOpenNav(!isOpenNav);
                }}
              />
            </div>
            <ul
              className={`${
                isOpenNav ? "" : "hidden"
              } absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:flex ml-auto mr-auto items-center w-auto space-x-12`}
            >
              <li>
                <Link
                  className="text-gray-50 hover:text-gray-200 text-xl"
                  href="/"
                >
                  {navbarMenu[lang || "en"].home}
                </Link>
              </li>
              <li>
                <Link
                  className="text-gray-50 hover:text-gray-200 text-xl"
                  href="/blogs"
                >
                  {navbarMenu[lang || "en"].blog}
                </Link>
              </li>
              <li>
                <Link
                  className="text-gray-50 hover:text-gray-200 text-xl"
                  href="/#faq-section"
                >
                  {navbarMenu[lang || "en"].faq}
                </Link>
              </li>
              <li>
                <Link
                  className="text-gray-50 hover:text-gray-200 text-xl"
                  href="/about"
                >
                  {navbarMenu[lang || "en"].about}
                </Link>
              </li>
            </ul>
            <ul className="hidden lg:flex items-center w-auto mx-4">
              <li>
                <LangButton />
              </li>
            </ul>
          </nav>
        </div>
        <div
          className={`bg-primary-500 backdrop-blur-sm ${
            isOpenNav ? "" : "hidden"
          } navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-40`}
        >
          <div className="navbar-backdrop fixed inset-0 bg-primary-800 opacity-25" />
          <nav className="relative flex flex-col py-6 px-6 w-full h-full bg-white border-r overflow-y-auto">
            <div className="flex items-center mb-8">
              <Link
                className="mr-auto text-2xl font-semibold leading-none"
                href="/"
              >
                {image && (
                  <Image
                    src={`${process.env.NEXT_PUBLIC_API_ENDPOINT}${image}`}
                    alt="rayongboard-logo-mobile"
                    width={256}
                    height={48}
                  />
                )}
              </Link>
              <NavClosing
                onClick={() => {
                  setIsOpenNav(!isOpenNav);
                }}
              />
            </div>
            <div>
              <ul>
                <li className="mb-1">
                  <Link
                    className="block p-4 text-sm font-semibold text-gray-900 hover:bg-primary-50 rounded"
                    href="/"
                    onClick={() => {
                      setIsOpenNav(!isOpenNav);
                    }}
                  >
                    {navbarMenu[lang || "en"].home}
                  </Link>
                </li>
                <li className="mb-1">
                  <Link
                    className="block p-4 text-sm font-semibold text-gray-900 hover:bg-primary-50 rounded"
                    href="/blogs"
                    onClick={() => {
                      setIsOpenNav(!isOpenNav);
                    }}
                  >
                    {navbarMenu[lang || "en"].blog}
                  </Link>
                </li>
                <li className="mb-1">
                  <Link
                    className="block p-4 text-sm font-semibold text-gray-900 hover:bg-primary-50 rounded"
                    href="/#faq-section"
                    onClick={() => {
                      setIsOpenNav(!isOpenNav);
                    }}
                  >
                    {navbarMenu[lang || "en"].faq}
                  </Link>
                </li>
                <li className="mb-1">
                  <Link
                    className="block p-4 text-sm font-semibold text-gray-900 hover:bg-primary-50 rounded"
                    href="/about"
                    onClick={() => {
                      setIsOpenNav(!isOpenNav);
                    }}
                  >
                    {navbarMenu[lang || "en"].about}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="mt-auto">
              <div className="pt-6">
                <LangButton />
              </div>
              <p className="mt-6 mb-4 text-sm text-center text-gray-400">
                <span>© 2021 All rights reserved.</span>
              </p>
            </div>
          </nav>
        </div>
      </section>
    </>
  );
};

export default Navbar;
